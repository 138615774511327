import React, { useState } from 'react';
import { Calendar, MapPin, Beer, Coffee } from 'lucide-react';
import golfClubImage from './golf-club.png';
import logo from './Marina Golf Club logo.png';

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const bookingUrl = "https://bqyg3ighfi9.typeform.com/to/zMov8KFs";

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-400 to-blue-500 text-white">
      <nav className="bg-white bg-opacity-10 p-4">
        <div className="container mx-auto flex justify-between items-center">
          <div className='flex space-x-4 items-center'>
          <img src={logo} alt="Marina Golf Club" className="w-16" />
          <span className="text-2xl font-bold">Marina Golf Club</span>
          </div>
          <div className="hidden md:flex space-x-4">
            <a href="#about" className="hover:text-yellow-300">About</a>
            <a href="#features" className="hover:text-yellow-300">Features</a>
            <a href={bookingUrl} className="hover:text-yellow-300">Book Now</a>
          </div>
          <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="md:hidden">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </div>
      </nav>
      
      {isMenuOpen && (
        <div className="md:hidden bg-white bg-opacity-10 p-4">
          <a href="#about" className="block py-2 hover:text-yellow-300">About</a>
          <a href="#features" className="block py-2 hover:text-yellow-300">Features</a>
          <a href={bookingUrl} className="block py-2 hover:text-yellow-300">Book Now</a>
        </div>
      )}

    <header className="container mx-auto px-4 py-12 md:py-20 lg:py-24">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="w-full md:w-1/3 mb-8 md:mb-0">
                <img src={golfClubImage} alt="Golf Club" className="w-full max-w-sm mx-auto rounded-lg shadow-lg" />
              </div>
              <div className="w-full md:w-2/3 md:pl-12 text-center md:text-left">
                <h1 className="text-4xl lg:text-5xl font-bold mb-4">Marina Golf Simulator Pop-Up</h1>
                <p className="text-xl mb-8">Experience golf like never before in the heart of San Francisco!</p>
                <a 
                  href={bookingUrl} 
                  className="inline-block bg-yellow-400 text-gray-900 px-8 py-3 rounded-full font-bold hover:bg-yellow-300 transition duration-300 transform hover:scale-105"
                >
                  Reserve Your Spot
                </a>
              </div>
            </div>
          </header>
      <main className="container mx-auto px-4">
        <section id="about" className="mb-20">
          <h2 className="text-3xl font-bold mb-4">About Our Pop-Up</h2>
          <p className="text-lg">Join us for a limited-time event featuring state-of-the-art golf simulators, delicious snacks, and refreshing beverages. Perfect your swing while enjoying the vibrant atmosphere of San Francisco's Marina district.</p>
        </section>

        <section id="features" className="grid md:grid-cols-2 gap-8 mb-20">
          <div className="bg-white bg-opacity-20 p-6 rounded-lg">
            <Calendar className="w-12 h-12 mb-4" />
            <h3 className="text-xl font-bold mb-2">Flexible Scheduling</h3>
            <p>Book your session anytime during our operating hours.</p>
          </div>
          <div className="bg-white bg-opacity-20 p-6 rounded-lg">
            <MapPin className="w-12 h-12 mb-4" />
            <h3 className="text-xl font-bold mb-2">Prime Location</h3>
            <p>Conveniently located in the heart of the Marina district.</p>
          </div>
          <div className="bg-white bg-opacity-20 p-6 rounded-lg">
            <Beer className="w-12 h-12 mb-4" />
            <h3 className="text-xl font-bold mb-2">Refreshing Beverages</h3>
            <p>Enjoy a cold beer while you perfect your game.</p>
          </div>
          <div className="bg-white bg-opacity-20 p-6 rounded-lg">
            <Coffee className="w-12 h-12 mb-4" />
            <h3 className="text-xl font-bold mb-2">Snacks</h3>
            <p>Savor our delicious hot dogs and other treats.</p>
          </div>
        </section>

        <section id="booking" className="text-center mb-20">
          <h2 className="text-3xl font-bold mb-4">Ready to Smash?</h2>
          <p className="text-lg mb-8">Reserve your spot now and get ready for an unforgettable experience!</p>
          <a href={bookingUrl} className="bg-yellow-400 text-gray-900 px-8 py-3 rounded-full font-bold hover:bg-yellow-300 transition duration-300">Reserve Your Spot</a>
        </section>
      </main>

      <footer className="bg-white bg-opacity-10 py-8">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 Marina Golf Club. All rights reserved.</p>
          <p><a href="mailto:joe.shamon@outlook.com" className="hover:text-yellow-300">Contact Us</a></p>
        </div>
      </footer>
    </div>
  );
};

export default App;